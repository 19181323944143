import type {USPType, modeType} from '@smart/editorial-components';
import {USP} from '@smart/editorial-components';
import type {UspContent} from '@smart/website-aem-utils';
import {createLinkProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useLink} from '../hooks/use-link';
import {PageModelContext} from './page';
export interface UspWrapperProps {
  readonly content: UspContent;
}

export const UspWrapper = React.memo<UspWrapperProps>(
  ({content}) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};
    const {historyService} = useFeatureServices();
    const props: USPType = {
      removeTopSpacing: content.removeTopSpacing || false,
      mode: content.mode as modeType,
      variant: content.variant || `full-width`,
      layout: content.layout,
      subline: content.subline,
      iconType: content.iconType || `icon`,
      headline: content.headline,
      paragraph: content.paragraph,
      uspItmes: content.uspItems?.map(
        ({iconName, description, highlightSubline, title, textLink}) => {
          return {
            iconName,
            description,
            highlightSubline,
            title,
            textLink: textLink
              ? createLinkProps({
                  historyService,
                  content: textLink,
                })
              : undefined,
          };
        },
      ),
      link: useLink(content.link, {
        websiteTrackingData: {
          type: `navigation`,
          region: `usp`,
          name: content.link?.label || ``,
        },
        languageTag,
        marketId,
      }),
    };

    return <USP {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

USP.displayName = `Usp`;
