import {MidBanner} from '@smart/editorial-components';
import type {MidBannerContent} from '@smart/website-aem-utils';
import {
  createLegalDisclaimerProps,
  createLinkProps,
} from '@smart/website-aem-utils';
import type {
  CampaignItemType,
  OfferItemType,
} from '@smart/website-aem-utils/src/contents/create-midbanner-content';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {PageModelContext} from './page';

export interface MidBannerWrapperProps {
  readonly content: MidBannerContent;
}

export const MidBannerWrapper = React.memo(
  ({content}: MidBannerWrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const {historyService, endpointDirectory, loginService} =
      useFeatureServices();

    const props = {
      removeTopSpacing: content.removeTopSpacing,
      variant: content.variant,
      mode: content.modeSelector,
      Items: content.items.map((open) => {
        if (content.variant === `offer`) {
          const item = open as OfferItemType;
          return {
            landscapeImage: item.imageLandscapeUrl
              ? {
                  imageUrl: item.imageLandscapeUrl || ``,
                  imageAltText: item.mediaAltText || ``,
                }
              : undefined,
            price: item.price,
            fromLabel: item.fromLabel,
            headline: item.headLine,
            subHeadline: item.subHeadLine,
            backgroundColorOption: item.backgroundColorOption,
            portraitImage: item.imagePortraitUrl
              ? {
                  imageUrl: item.imagePortraitUrl || ``,
                  imageAltText: item.mediaAltText || ``,
                }
              : undefined,
            primaryLink: item.primaryLink
              ? createLinkProps({
                  historyService,
                  content: item.primaryLink,
                  websiteTrackingData: {
                    type: `navigation`,
                    region: `mid-banner`,
                    name: item.primaryLink?.label,
                  },
                  envName: endpointDirectory.envName,
                  loginState: loginService?.loginState,
                  languageTag,
                  marketId,
                })
              : undefined,
          };
        } else {
          const item = open as CampaignItemType;
          return {
            landscapeImage: item.imageLandscapeUrl
              ? {
                  imageUrl: item.imageLandscapeUrl || ``,
                  imageAltText: item.mediaAltText || ``,
                }
              : undefined,
            headline: item.headLine,
            subHeadline: item.subHeadLine,
            portraitImage: item.imagePortraitUrl
              ? {
                  imageUrl: item.imagePortraitUrl || ``,
                  imageAltText: item.mediaAltText || ``,
                }
              : undefined,
            primaryLink: item.primaryLink
              ? createLinkProps({
                  historyService,
                  content: item.primaryLink,
                  websiteTrackingData: {
                    type: `navigation`,
                    region: `mid-banner`,
                    name: item.primaryLink?.label,
                  },
                  envName: endpointDirectory.envName,
                  loginState: loginService?.loginState,
                  languageTag,
                  marketId,
                })
              : undefined,
            logo: item.logo
              ? {
                  size: item.logo.size,
                  url: item.logo.url || ``,
                  link: item.logo.link
                    ? createLinkProps({
                        historyService,
                        content: item.logo.link,
                      })
                    : undefined,
                }
              : undefined,
          };
        }
      }),
      legalDisclaimers: createLegalDisclaimerProps(
        content.legalDisclaimers,
        historyService,
      ),
    };

    return (
      <>
        <MidBanner {...props} />
      </>
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

MidBanner.displayName = `MidBannerWrapper`;
