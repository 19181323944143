import {createId, sanitizeGraphqlData} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface FeatureAppContent {
  readonly type: `feature-app`;
  readonly id: string;
  readonly anchorId: string;
  readonly featureAppName: string;
  readonly modelType: FeatureAppModelType;
  readonly baseUrl: string;
  readonly src: string;
  readonly config: unknown;
  readonly mboxName: string | undefined;
}

export type FeatureAppModelType = z.TypeOf<
  typeof FeatureAppContentFragment
>['__typename'];

const FeatureAppContentFragment = z
  .discriminatedUnion(`__typename`, [
    z.object({
      __typename: z.literal(`ExampleFeatureAppModel`),
      variant: z.string().nullish(),
    }),
    z.object({
      __typename: z.literal(`FormsFeatureAppModel`),
      variant: z.string().nullish(),
      campaignId: z.string().nullish(),
    }),
    z.object({
      __typename: z.literal(`OutletLocatorFeatureAppModel`),
      variant: z.string().nullish(),
    }),
    z.object({
      __typename: z.literal(`ProductPromotionalObjectFeatureAppModel`),
      productId: z.string().nullish(),
    }),
    z.object({
      __typename: z.literal(`VisualizerFeatureAppModel`),
      variant: z.string(),
      line: z.string(),
      model: z.string().nullish(),
      pn18: z.string().nullish(),
      darkMode: z.boolean().nullish(),
    }),
    z.object({__typename: z.literal(`AddOnFeatureAppModel`)}),
    z.object({
      __typename: z.literal(`CarComparisonFeatureAppModel`),
      isRenderedInPage: z.boolean().nullish(),
    }),
    z.object({__typename: z.literal(`CustomizerFeatureAppModel`)}),
    z.object({__typename: z.literal(`DeleteAccountFeatureAppModel`)}),
    z.object({__typename: z.literal(`DocumentUploadFeatureAppModel`)}),
    z.object({__typename: z.literal(`EsignFeatureAppModel`)}),
    z.object({__typename: z.literal(`LineSelectorFeatureAppModel`)}),
    z.object({__typename: z.literal(`MyAccountFeatureAppModel`)}),
    z.object({__typename: z.literal(`PreOrderFeatureAppModel`)}),
    z.object({__typename: z.literal(`ProductCatalogueFeatureAppModel`)}),
    z.object({__typename: z.literal(`ProductSummaryFeatureAppModel`)}),
    z.object({__typename: z.literal(`ShoppingCartFeatureAppModel`)}),
    z.object({__typename: z.literal(`StockSearchFeatureAppModel`)}),
    z.object({
      __typename: z.literal(`TestDriveFeatureAppModel`),
      campaignId: z.string().nullish(),
    }),
    z.object({
      __typename: z.literal(`TestDriveCampaignFeatureAppModel`),
      campaignId: z.string().nullish(),
    }),
    z.object({
      __typename: z.literal(`SmartChoicesFeatureAppModel`),
      productCode1: z.string().nullish(),
      productCode2: z.string().nullish(),
      productCode3: z.string().nullish(),
      hideTitle: z.boolean().nullish(),
      removeTopSpacing: z.boolean().nullish(),
    }),
  ])
  .and(
    z.object({
      version: z.object({
        featureAppName: z.string(),
        versionHash: z.string(),
        src: z.string(),
      }),
      mboxName: z.string().nullish(),
    }),
  );

export function createFeatureAppContent(
  contentFragment: Extract<
    AemContentFragment,
    {
      __typename:
        | 'AddOnFeatureAppModel'
        | 'CarComparisonFeatureAppModel'
        | 'CustomizerFeatureAppModel'
        | 'DeleteAccountFeatureAppModel'
        | 'DocumentUploadFeatureAppModel'
        | 'EsignFeatureAppModel'
        | 'ExampleFeatureAppModel'
        | 'FormsFeatureAppModel'
        | 'LineSelectorFeatureAppModel'
        | 'MyAccountFeatureAppModel'
        | 'OutletLocatorFeatureAppModel'
        | 'PreOrderFeatureAppModel'
        | 'ProductCatalogueFeatureAppModel'
        | 'ProductPromotionalObjectFeatureAppModel'
        | 'ProductSummaryFeatureAppModel'
        | 'ShoppingCartFeatureAppModel'
        | 'StockSearchFeatureAppModel'
        | 'TestDriveFeatureAppModel'
        | 'VisualizerFeatureAppModel'
        | 'TestDriveCampaignFeatureAppModel'
        | 'SmartChoicesFeatureAppModel';
    }
  >,
): FeatureAppContent {
  const {
    __typename,
    version: {featureAppName, versionHash, src},
    mboxName,
    ...config
  } = sanitizeGraphqlData(FeatureAppContentFragment, contentFragment);

  return {
    type: `feature-app`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    featureAppName,
    modelType: __typename,
    baseUrl: `/__app__/${featureAppName}/${versionHash}`,
    src,
    config,
    mboxName,
  };
}
