import type {LocaleV3} from '@smart/locale-service';
import {useEffect} from 'react';
import {
  isValidInterruptionEndPath,
  isValidInterruptionStartPath,
} from '../utils';
import {getInterruptionPage} from '../utils/get-interruption-page';
import type {HistoryPath} from './use-history-path';

type UseInterruptionCallback = {
  setHasPopupBeenDisplayed: (value: boolean) => void;
  setInterruptionPageName: (value: string) => void;
  path: HistoryPath;
  locale: LocaleV3;
};
export const useInterruptionCallback = ({
  setHasPopupBeenDisplayed,
  setInterruptionPageName,
  path,
  locale,
}: UseInterruptionCallback) => {
  useEffect(() => {
    const wasJourneyInterrupted =
      isValidInterruptionStartPath(path.previousPath, locale) &&
      isValidInterruptionEndPath(path.currentPath, locale);

    const interruptedPageName = getInterruptionPage({locale, path});

    if (wasJourneyInterrupted) {
      setHasPopupBeenDisplayed(true);
      setInterruptionPageName(interruptedPageName);
      return;
    }
    setHasPopupBeenDisplayed(false);
  }, [path]);
};
