import type {HeaderType} from '@smart/editorial-components';
import {Header} from '@smart/editorial-components';
import type {LinkType} from '@smart/web-components';
import type {HeaderRef} from '@smart/web-components/dist/types/components/header';
import type {IconType} from '@smart/web-components/dist/types/icons';
import type {LinkContent} from '@smart/website-aem-utils';
import {createLinkProps} from '@smart/website-aem-utils';
import type {HeaderV2Content} from '@smart/website-page-model';
import type {HeaderV2CampaignBannerContent} from '@smart/website-page-model/src/create-header-v2-content';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useGarageDetails} from '../hooks/use-garage-details';
import {useIsLoggedIn} from '../hooks/use-is-logged-in';
import {useLanguageContext} from '../hooks/use-language-context';
import {useLink} from '../hooks/use-link';
import {useShoppingCartDetails} from '../hooks/use-shopping-cart-details';
import {PageModelContext} from './page';

export interface HeaderV2WrapperProps {
  readonly content: HeaderV2Content;
  readonly heroTemplate?: 'award' | 'offer' | 'campaign';
  readonly backgroundColor?: string | undefined;
}

export const HeaderV2Wrapper = React.memo(
  ({content, heroTemplate, backgroundColor}: HeaderV2WrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const {endpointDirectory, loginService, historyService} =
      useFeatureServices();
    const headerRef = React.useRef<HeaderRef>(null);
    const isLoggedIn = useIsLoggedIn();
    const closeMenu = React.useCallback(
      () => headerRef.current?.closeMenu(),
      [],
    );

    React.useEffect(() => historyService.history.listen(closeMenu), []);

    const {selectedLanguage, setSelectedLanguage} = useLanguageContext();
    const [_, startLanguageChangeTransition] = React.useTransition();

    const handleLanguageChange = (val: string) => {
      setSelectedLanguage(val);

      startLanguageChangeTransition(() => {
        window.location.href = window.location.pathname.replace(
          /^\/([^/]*)\//,
          `/${val}/`,
        );
      });
    };

    const logoLink = useLink(content.logoLink, {onBeforePush: closeMenu});

    const myAccountLink = useLink(content.myAccountLink, {
      onBeforePush: closeMenu,
    });

    const shoppingCartLink = useLink(content.shoppingCartLink, {
      onBeforePush: closeMenu,
    });

    const shoppingCartDetails = useShoppingCartDetails({
      enabled: shoppingCartLink !== undefined,
    });

    const myGarageLink = useLink(content.myGarageLink, {
      consumerLocations: [
        {historyKey: `my-account`, location: {pathname: `/garage`}},
      ],
      onBeforePush: closeMenu,
    });

    const logoutLink = {
      href: `#`,
      label: content.logoutLabel || `Logout`,
      onClick: () => {
        closeMenu();
        loginService?.logout();
      },
    };

    const garageDetails = useGarageDetails({
      enabled: myGarageLink !== undefined,
    });

    const notEmpty = <T,>(value: T | null | undefined): value is T => {
      return value !== null && value !== undefined;
    };

    const getLink = (
      name: string,
      link?: LinkContent,
      type?: string,
      region?: string,
    ) => {
      return link
        ? createLinkProps({
            historyService,
            onBeforePush: closeMenu,
            content: link,
            websiteTrackingData: {
              type: type || `navigation`,
              region: region || `main_nav.quick_menu`,
              name,
            },
            envName: endpointDirectory.envName,
            loginState: loginService?.loginState,
            languageTag,
            marketId,
          })
        : undefined;
    };

    const visibleMenuItems = React.useMemo<
      HeaderType['visibleMenuItems']
    >(() => {
      return content.visibleMenuItems.filter(notEmpty).map((i) => {
        if (i.type === `tiles`) {
          return {
            label: i.label || ``,
            tiles: i.tiles.map((t, index) => {
              if (t.image) {
                return {
                  tileType: `carTile`,
                  label: t.headline || ``,
                  price: t.price || ``,
                  imageUrl: t.image.imageUrl,
                  link: getLink(`${t.headline}.${index}`, t.link),
                  primaryLink: getLink(
                    `${t.headline}.${index}.${t.primaryCta?.label}`,
                    t.primaryCta,
                  ),
                  secondaryLink: getLink(
                    `${t.headline}.${index}.${t.secondaryCta?.label}`,
                    t.secondaryCta,
                  ),
                };
              }

              return {
                tileType: `contentTile`,
                headlineText: t.headline || ``,
                launchText: t.price || ``,
                link: getLink(`${t.headline}.${index}`, t.link),
              };
            }),
          };
        }
        return createLinkProps({
          historyService,
          onBeforePush: closeMenu,
          content: i,
          websiteTrackingData: {
            type: `navigation`,
            region: `main_nav`,
            name: i.label,
          },
          envName: endpointDirectory.envName,
          loginState: loginService?.loginState,
          languageTag,
          marketId,
        });
      });
    }, [content]);

    const getCampaignBanner = (
      myContent?: HeaderV2CampaignBannerContent,
    ): HeaderType['campaign'] => {
      return myContent
        ? {
            mediaType: myContent.mediaType || `image`,
            media: myContent.mediaUrl || ``,
            logo: myContent.logo
              ? {
                  size: myContent.logo.size,
                  url: myContent.logo.url || ``,
                  link: myContent.logo.link
                    ? createLinkProps({
                        historyService,
                        content: myContent.logo.link,
                      })
                    : undefined,
                }
              : undefined,
            variant: myContent.variant || `campaign`,
            backgroundColorOption:
              myContent.backgroundcoloroption || `premium #1`,
            fromLabel: myContent.fromLabel || ``,
            price: myContent.price || ``,
            headline: myContent.headline,
            link: myContent.link
              ? createLinkProps({
                  historyService,
                  onBeforePush: closeMenu,
                  content: myContent.link,
                })
              : undefined,
            description: myContent.description,
          }
        : undefined;
    };

    const navigationItems = React.useMemo<HeaderType['navigationItems']>(() => {
      const items = content.navigationItems.map((i) => {
        return {
          isHighlightedLinks: i.isHighlightedLinks,
          categoryLabel: i.categoryLabel || ``,
          menuItems: i.menuItems.filter(notEmpty).map((m) => {
            if (m.type === `has-second-level`) {
              return {
                label: m.label || ``,
                byline: m.byline || ``,
                price: m.priceTag,
                imageUrl: m.image?.imageUrl,
                campaign: getCampaignBanner(m.campaignBanner),
                subItems: m.secondLevel
                  ? m.secondLevel.map((l) => ({
                      categoryLabel: l.categoryLabel || ``,
                      menuItems: l.menuItems.filter(notEmpty).map((x) => ({
                        ...createLinkProps({
                          historyService,
                          onBeforePush: closeMenu,
                          content: x,
                          websiteTrackingData: {
                            type: `navigation`,
                            region: `main_nav.overlay`,
                            name: `${m.label}.${x.label}`,
                          },
                          envName: endpointDirectory.envName,
                          loginState: loginService?.loginState,
                          languageTag,
                          marketId,
                        }),
                        campaign: getCampaignBanner(x.campaignBanner),
                      })),
                    }))
                  : [],
              };
            }

            return {
              ...createLinkProps({
                historyService,
                onBeforePush: closeMenu,
                content: m.secondLink as LinkContent,
                websiteTrackingData: {
                  type: `navigation`,
                  region: `main_nav.overlay`,
                  name: m.label || ``,
                },
                envName: endpointDirectory.envName,
                loginState: loginService?.loginState,
                languageTag,
                marketId,
              }),
              campaign: getCampaignBanner(m.campaignBanner),
            };
          }),
        };
      });

      return items;
    }, [content]);

    const campaign = React.useMemo<HeaderType['campaign']>(() => {
      if (content.campaignBanner) {
        return getCampaignBanner(content.campaignBanner);
      }
      return undefined;
    }, [content]);

    const {
      menuButtonLabel,
      closeButtonLabel,
      greetingText = ``,
      subMenuProps,
    } = content;

    return (
      <Header
        heroTemplate={heroTemplate}
        backgroundColor={backgroundColor}
        subMenuProps={
          subMenuProps
            ? {
                ...subMenuProps,
                navigation: getLink(
                  `${subMenuProps.navigation?.label}`,
                  subMenuProps.navigation,
                  `navigation`,
                  `sub_menu`,
                ),
                primaryLinkIcon: subMenuProps?.primaryLinkIcon as IconType,
                secondaryLinkIcon: subMenuProps?.secondaryLinkIcon as IconType,
                hideOnMobile: subMenuProps.hideOnMobile,
                primaryLink: getLink(
                  `${subMenuProps.primaryLink?.label}`,
                  subMenuProps.primaryLink,
                  `navigation`,
                  `sub_menu`,
                ),
                secondaryLink: getLink(
                  `${subMenuProps.secondaryLink?.label}`,
                  subMenuProps.secondaryLink,
                  `navigation`,
                  `sub_menu`,
                ),
                items:
                  subMenuProps.items && subMenuProps.items.length
                    ? subMenuProps.items.map(
                        (item, i) =>
                          getLink(
                            `${item?.label}.${i}`,
                            item,
                            `feature`,
                            `sub_menu`,
                          ) as LinkType,
                      )
                    : undefined,
              }
            : undefined
        }
        greetingText={greetingText}
        logoLink={logoLink}
        openMenuAriaLabel={menuButtonLabel}
        closeMenuAriaLabel={closeButtonLabel}
        myAccountLink={myAccountLink}
        myGarageBadge={garageDetails?.itemCount}
        myGarageLink={myGarageLink}
        myShoppingBadge={shoppingCartDetails?.itemCount}
        myShoppingLink={shoppingCartLink}
        logoutLink={logoutLink}
        visibleMenuItems={visibleMenuItems}
        navigationItems={navigationItems}
        campaign={campaign}
        isLoggedIn={isLoggedIn}
        languageSwitch={
          content.localeSwitch?.languageSwitchLabel &&
          content.localeSwitch.languageOptions.length > 1
            ? {
                label: content.localeSwitch.languageSwitchLabel,
                value: selectedLanguage,
                onChange: handleLanguageChange,
                options: content.localeSwitch.languageOptions,
              }
            : undefined
        }
        ref={headerRef}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.content?.subMenuProps === nextProps.content?.subMenuProps &&
    prevProps.backgroundColor === nextProps.backgroundColor &&
    prevProps.heroTemplate === nextProps.heroTemplate,
);

HeaderV2Wrapper.displayName = `HeaderV2Wrapper`;
