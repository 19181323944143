import type {CreateMediaUrlOptions, DeepExcludeNull} from '@smart/aem-utils';
import {createId, createMediaUrl, sanitizeGraphqlData} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface ScrollytellingItemContent {
  readonly videoLandscape?: string;
  readonly videoPortrait?: string;
  readonly videoAltText?: string;
  readonly headline?: string;
  readonly description?: string;
  readonly listTitle?: string;
  readonly typeList?: string;
  readonly highlightTitle?: string;
  readonly highlightData?: string;
  readonly highlightDataUnit?: string;
  readonly highlightDescription?: string;
  readonly highlight2Title?: string;
  readonly highlight2Data?: string;
  readonly highlight2DataUnit?: string;
  readonly highlight2Description?: string;
  readonly cursorText?: string;
  readonly cursorDownText?: string;
}

export interface ScrollytellingContent {
  readonly type: 'scrollytelling';
  readonly id: string;
  readonly anchorId: string;
  readonly scrollyList: ScrollytellingItemContent[];
  readonly soundOnLabel?: string;
  readonly soundOffLabel?: string;
  readonly modeSelector?: string;
  readonly removeTopSpacing?: boolean;
  readonly mboxName?: string;
}

const ScrollytellingItemContentFragment = z.object({
  videoLandscape: z.object({_path: z.string()}).nullish(),
  videoPortrait: z.object({_path: z.string()}).nullish(),
  videoAltText: z.string().nullish(),
  headline: z.string().nullish(),
  description: z.string().nullish(),
  listTitle: z.string().nullish(),
  typeList: z.string().nullish(),
  highlightTitle: z.object({markdown: z.string().nullish()}).nullish(),
  highlightData: z.string().nullish(),
  highlightDataUnit: z.string().nullish(),
  highlightDescription: z.object({markdown: z.string().nullish()}).nullish(),
  highlight2Title: z.object({markdown: z.string().nullish()}).nullish(),
  highlight2Data: z.string().nullish(),
  highlight2DataUnit: z.string().nullish(),
  highlight2Description: z.object({markdown: z.string().nullish()}).nullish(),
  cursorText: z.string().nullish(),
  cursorDownText: z.string().nullish(),
});

const ScrollytellingContentFragment = z.object({
  scrollyList: z.array(ScrollytellingItemContentFragment),
  soundOnLabel: z.string().nullish(),
  soundOffLabel: z.string().nullish(),
  modeSelector: z.string().nullish(),
  removeTopSpacing: z.boolean().nullish(),
  mboxName: z.string().nullish(),
});

export function createScrollytellingContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'ScrollytellingModel'}
  >,
  options: CreateMediaUrlOptions,
): ScrollytellingContent {
  const {
    modeSelector,
    scrollyList,
    soundOnLabel,
    soundOffLabel,
    removeTopSpacing,
    mboxName,
  } = sanitizeGraphqlData(ScrollytellingContentFragment, contentFragment);

  return {
    type: `scrollytelling`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    modeSelector,
    scrollyList: createScrollytellingItemContents(scrollyList, options),
    soundOnLabel,
    soundOffLabel,
    removeTopSpacing,
    mboxName,
  };
}

function createScrollytellingItemContents(
  scrollyList: readonly DeepExcludeNull<
    z.TypeOf<typeof ScrollytellingItemContentFragment>
  >[],
  options: CreateMediaUrlOptions,
): ScrollytellingItemContent[] {
  return scrollyList.map(
    ({
      videoLandscape,
      videoPortrait,
      videoAltText,
      headline,
      description,
      listTitle,
      typeList,
      highlightTitle,
      highlightData,
      highlightDataUnit,
      highlightDescription,
      highlight2Title,
      highlight2Data,
      highlight2DataUnit,
      highlight2Description,
      cursorText,
      cursorDownText,
    }) => ({
      videoLandscape:
        videoLandscape != null
          ? createMediaUrl(`content`, videoLandscape._path, options)
          : undefined,
      videoPortrait:
        videoPortrait != null
          ? createMediaUrl(`content`, videoPortrait._path, options)
          : undefined,
      videoAltText,
      headline,
      description,
      listTitle,
      typeList,
      highlightTitle: highlightTitle?.markdown,
      highlightData,
      highlightDataUnit,
      highlightDescription: highlightDescription?.markdown,
      highlight2Title: highlight2Title?.markdown,
      highlight2Data,
      highlight2DataUnit,
      highlight2Description: highlight2Description?.markdown,
      cursorText,
      cursorDownText,
    }),
  );
}
