import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LinkContent,
} from '@smart/aem-utils';
import {
  LinkDestination,
  createLinkContent,
  createMediaUrl,
} from '@smart/aem-utils';
import {z} from 'zod';
export interface LogoContainerContent {
  size?: 'large' | 'medium' | 'small';
  link?: LinkContent;
  imageAltText?: string;
  url?: string;
}

export const LogoContainerContentFragment = z.object({
  image: z.object({_path: z.string()}).nullish(),
  imageAltText: z.string().nullish(),
  size: z.string().nullish(),
  link: LinkDestination.nullish(),
});

export function createLogoContainer(
  logoContainer?: DeepExcludeNull<
    z.TypeOf<typeof LogoContainerContentFragment>
  >,
  options?: CreateMediaUrlOptions,
): LogoContainerContent | undefined {
  if (!logoContainer || !options) {
    return undefined;
  }

  const {size, image, link, imageAltText = ``} = logoContainer;

  return {
    size: size as LogoContainerContent['size'],
    imageAltText,
    url: image ? createMediaUrl(`image`, image._path, options) : ``,
    link: link
      ? createLinkContent({
          destination: link,
          label: ``,
        })
      : undefined,
  };
}
