import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LinkContent,
} from '@smart/aem-utils';
import {
  RequiredLinkDestination,
  createId,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface TeaserRowContent {
  readonly type: 'teaser-row';
  readonly id: string;
  readonly anchorId: string;
  readonly componentHeadline: string | undefined;
  readonly indicatorBar: boolean | undefined;
  readonly items: TeaserRowItemContent[];
  readonly mboxName: string | undefined;
}

export interface TeaserRowItemContent {
  readonly imageUrl: string;
  readonly imageAltText: string;
  readonly link: LinkContent;
}

const TeaserRowItemContentFragment = z.object({
  headline: z.string(),
  image: z.object({_path: z.string()}),
  imageAltText: z.string(),
  linkDestination: RequiredLinkDestination,
});

const TeaserRowContentFragment = z.object({
  componentHeadline: z.string().nullish(),
  indicatorBar: z.boolean().nullish(),
  items: z.array(TeaserRowItemContentFragment),
  mboxName: z.string().nullish(),
});

export function createTeaserRowContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'TeaserRowModel'}>,
  options: CreateMediaUrlOptions,
): TeaserRowContent {
  const {componentHeadline, indicatorBar, items, mboxName} =
    sanitizeGraphqlData(TeaserRowContentFragment, contentFragment);

  return {
    type: `teaser-row`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    componentHeadline,
    items: createTeaserRowItemContents(items, options),
    indicatorBar,
    mboxName,
  };
}

function createTeaserRowItemContents(
  items: readonly DeepExcludeNull<
    z.TypeOf<typeof TeaserRowItemContentFragment>
  >[],
  options: CreateMediaUrlOptions,
): TeaserRowItemContent[] {
  return items.map(({headline, image, imageAltText, linkDestination}) => ({
    imageUrl: createMediaUrl(`image`, image._path, options),
    imageAltText,
    link: createLinkContent({destination: linkDestination, label: headline}),
  }));
}
