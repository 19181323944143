import type {LocaleV3} from '@smart/locale-service';
import {safeJsonParse} from '@smart/utils';
import {INTERRUPTION_START_PATHS} from '../constants';
import type {HistoryPath} from '../hooks';
import {getLanguageMappedPaths} from './get-language-mapped-paths';

interface GetInterruptionPage {
  locale: LocaleV3;
  path: HistoryPath;
}

export function getInterruptionPage({locale, path}: GetInterruptionPage) {
  const previousFullPathDecoded = decodeURIComponent(
    `${path.previousPath}${path.previousLocationSearch}`,
  );
  const paths = getLanguageMappedPaths(INTERRUPTION_START_PATHS, locale);
  const interruptedPath = paths.find(
    (_path) => decodeURIComponent(_path) === previousFullPathDecoded,
  );

  if (!interruptedPath) {
    return `unknown`;
  }

  const withQueryString = interruptedPath.split(`?`)[1];

  if (!withQueryString) {
    const page = interruptedPath.split(`/${locale.language}`)[1];

    if (!page) {
      return `unknown`;
    }
    return page;
  }

  const params = new URLSearchParams(withQueryString);
  const paramsObj = params.get(`---`);

  if (!paramsObj) {
    return `unknown`;
  }
  const parsedParams = safeJsonParse<{'shopping-cart': string}>(paramsObj);

  if (!parsedParams) {
    return `unknown`;
  }

  return parsedParams[`shopping-cart`];
}
